export const data = {
  overview: {
    backlogChallenge: {
      year: 2019,
      completed: 0,
      goal: 6,
    },
    stats: [
      [24, "Participants"],
      [144, "Games pledged"],
      [6, "Games pledged by each participant"],
      ["56 days, 17 hours", "Time left for playing"],
    ],
    schedule: [2, 40],
  },
  accountStats: {
    ownedGames: 100,
    playedGames: 50,
    neverPlayedGames: 50,
    totalGamingHours: 2000,
    avgGamingHours: 20,
    medianGamingHours: 2,
  },
  accountCharts: {
    backlogPie: [
      { tag: "Played games", value: 50 },
      { tag: "Never played games", value: 50 },
    ],
    hoursToPlayChart: [
      { name: "Hours to Play", playedHours: 2000, hoursStillToPlay: 10000 },
    ],
    gamesByTimePlayedChart: [
      { name: "Never played", value: 50 },
      { name: "0 to 1", value: 15 },
      { name: "1 to 2", value: 7 },
      { name: "2 to 3", value: 8 },
      { name: "3 to 6", value: 12 },
      { name: "6 to 12", value: 11 },
      { name: "12 or more", value: 5 },
    ],
  },
  playNext: {
    wantToPlay: {
      headers: [1, 2, 3, 4],
      amount: 96,
    },
    popular: {
      headers: [1, 2, 3, 4],
    },
    similar: {
      headers: [1, 2, 3, 4],
      similarGame: "Transistor",
    },
  },
  lastPlayed: [
    {
      header: "",
      gameName: "Factorio",
      userRating: 4,
      playedHours: 22,
    },
    {
      header: "",
      gameName: "Thomas was alone",
      userRating: 0,
      playedHours: 2,
    },
    {
      header: "",
      gameName: "Tales of Monkey Island Complete Pack: Chapter 4 - The Trial and Execution of Guybrush Threepwood",
      userRating: 5,
      playedHours: 1140,
    },
  ],
};
import React from "react";
import * as PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import { Typography, List } from "@material-ui/core";
import styled from "styled-components";

const ChartLegendDiv = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;
const Dot = styled.span`
  height: 0.9rem;
  width: 0.9rem;
  margin-right: 0.6rem;
  background-color: ${props => props.color};
  border-radius: 50%;
  display: inline-block;
`;

export const PieChartLegend = ({ data }) => (
  <ChartLegendDiv>
    <List component="ul" dense>
      {data.map(element => (
        <ListItem component="li" key={element.tag}>
          <Typography component="span" variant="caption" noWrap>
            <Dot color={element.color} />
            {element.tag}
          </Typography>
        </ListItem>
      ))}
    </List>
  </ChartLegendDiv>
);
PieChartLegend.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    tag: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired).isRequired,
};

export const BarChartLegend = ({ legend }) => (
  <ChartLegendDiv>
    <List component="ul" dense>
      {legend.map(element => (
        <ListItem component="li" key={element.tag}>
          <Typography component="span" variant="caption" noWrap>
            <Dot color={element.color} />
            {element.tag}
          </Typography>
        </ListItem>
      ))}
    </List>
  </ChartLegendDiv>
);
BarChartLegend.propTypes = {
  legend: PropTypes.arrayOf(PropTypes.shape({
    tag: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired).isRequired,
};
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import styled from "styled-components";
import BadgeItem from "./BadgeItem";

const InnerDiv = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: space-around;
`;

const BadgesColumn = () => (
  <>
    <Typography component="h2" variant="h6" gutterBottom>My Badges</Typography>
    <InnerDiv>
      <BadgeItem caption="Early Bird" outline="gold" />
      <BadgeItem caption="Connected Steam account" outline="bronze" />
    </InnerDiv>
  </>
);

export default BadgesColumn;
import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { grey3 } from "../element/theme";

const BorderedDiv = styled.div`
  border: solid ${grey3} 1px;
  margin: 2rem 1.5rem 0 1.5rem;
  padding: 0.5rem;
`;

const text = "How many games are left unplayed in your Steam account? Take the backlog Challenge. Play one game per week until the end of the year.";

const BorderedExplainer = () => {
  return (
    <BorderedDiv>
      <Typography variant="body1" align="center">{text}</Typography>
    </BorderedDiv>
  );
};

export default BorderedExplainer;
import React from "react";
import * as PropTypes from "prop-types";
import OoProgressBar from "../../element/chart/OoProgressBar";
import styled from "styled-components";
import { OoTypoLightGrey } from "../../element/page-element/OoTypo";

const BarContainer = styled.div`
  padding: 0 3rem;
`;
const SpaceBetweenTypoDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HorizontalBar = ({ ownedGames, playedGames }) => {
  const percentage = Math.floor((playedGames / ownedGames) * 100);
  const txt = [
    `${playedGames} out of ${ownedGames} games played`,
    `${percentage}%`,
  ];

  return (
    <BarContainer>
      <SpaceBetweenTypoDiv>
        <OoTypoLightGrey variant="caption">{txt[0]}</OoTypoLightGrey>
        <OoTypoLightGrey variant="caption">{txt[1]}</OoTypoLightGrey>
      </SpaceBetweenTypoDiv>
      <OoProgressBar barWidth="100%" percentProgress={percentage.toString()} />
    </BarContainer>
  );
};

HorizontalBar.propTypes = {
  playedGames: PropTypes.number.isRequired,
  ownedGames: PropTypes.number.isRequired,
};

export default HorizontalBar;
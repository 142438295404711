import React from "react";
import * as PropTypes from "prop-types";
import { BarChart, Bar, YAxis, ResponsiveContainer, Tooltip, Legend } from "recharts";
import {HoursToPlayChartTooltip} from "./BarChartTooltip";

const HoursToPlayChart = ({ colors, data, legend }) => {
  return (
    <ResponsiveContainer width="100%" height={190}>
      <BarChart
        data={data}
        margin={{
          top: 15, right: 30, left: 20, bottom: 5,
        }}
      >
        <YAxis stroke="#ffffff" />
        <Tooltip cursor={{ fill: "#00bfa5", opacity: 0.1 }} content={<HoursToPlayChartTooltip />} />
        <Bar name={legend[0].tag} dataKey="playedHours" fill={colors[1]} />
        <Bar name={legend[1].tag} dataKey="hoursStillToPlay" fill={colors[0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

HoursToPlayChart.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    playedHours: PropTypes.number.isRequired,
    hoursStillToPlay: PropTypes.number.isRequired,
  }).isRequired).isRequired,
  legend: PropTypes.arrayOf(PropTypes.shape({
    tag: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired).isRequired,
};
export default HoursToPlayChart;
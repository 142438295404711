import React from "react";
import * as PropTypes from "prop-types";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, Label } from "recharts";
import {GamesByTimePlayedChartTooltip} from "./BarChartTooltip";

const GamesByTimePlayedChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={220}>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{ top: 5, right: 0, left: 5, bottom: 20 }}
      >

        <YAxis stroke="#ffffff">
          <Label value="Games" angle={-90} position="left" fill="white" offset={-20} fontSize="120%"
                 style={{ textAnchor: "middle" }} />
        </YAxis>
        <XAxis stroke="#ffffff" dataKey="name"
               label={{
                 value: "Hours",
                 position: "bottom",
                 offset: 4,
                 fill: "white",
                 fontSize: "120%",
               }} />

        <Bar dataKey="value" fill="#00bfa5" />
        <Tooltip cursor={{ fill: "#00bfa5", opacity: 0.1 }} content={<GamesByTimePlayedChartTooltip />} />
      </BarChart>
    </ResponsiveContainer>
  );
};
GamesByTimePlayedChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired).isRequired,
};
export default GamesByTimePlayedChart;


import React from "react";
import * as PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import OoPieChart from "../../element/chart/OoPieChart";
import { PieChartLegend, BarChartLegend } from "./Legends";
import { OoChartGridContainer, OoChartGridItem } from "../../element/chart/OoChartGrid";
import HoursToPlayChart from "./HoursToPlayChart";
import GamesByTimePlayedChart from "./GamesByTimePlayedChart";

const PlaytimeContainer = styled.div`
  margin: 3rem 0;
`;

const TwoCOLORS = ["#0bdff2", "#3c00be"];
const COLORS = ["#3c00be", "#0bdff2", "#f15a24", "#ffdd00", "#50dc4b", "#00bfa5"];
const txt = [
  "Your Backlog Pie",
  "Remaining average playtime for your backlog",
  "Your playtime visualized",
  "Games by time played",
];

class AccountCharts extends React.PureComponent {
  constructor(props) {
    super(props);

    const accountChart = this.props.accountCharts.backlogPie.map(
      (element, index) => ({ ...element, color: TwoCOLORS[index] }));
    const hoursToPlayChart = this.props.accountCharts.hoursToPlayChart.map(
      (element, index) => ({ ...element, color: COLORS[index] }));
    const hoursToPlayLegend = [
      { tag: "Played hours", color: TwoCOLORS[0] },
      { tag: "Hours still to play", color: TwoCOLORS[1] },
    ];
    const gamesByTimePlayedChart = this.props.accountCharts.gamesByTimePlayedChart;

    this.state = {
      accountChart: accountChart,
      hoursToPlayChart: hoursToPlayChart,
      hoursToPlayLegend: hoursToPlayLegend,
      gamesByTimePlayedChart: gamesByTimePlayedChart,
    };
  }

  render() {
    const { accountChart, hoursToPlayChart, hoursToPlayLegend, gamesByTimePlayedChart } = this.state;
    return (
      <>
        <OoChartGridContainer>
          <OoChartGridItem>
            <Typography component="h2" variant="h6" align="center">{txt[0]}</Typography>
            <OoPieChart colors={TwoCOLORS} data={accountChart} />
            <PieChartLegend data={accountChart} />
          </OoChartGridItem>

          <OoChartGridItem>
            <Typography component="h2" variant="h6" align="center">{txt[1]}</Typography>
            <HoursToPlayChart colors={COLORS} data={hoursToPlayChart} legend={hoursToPlayLegend} />
            <BarChartLegend data={hoursToPlayChart} legend={hoursToPlayLegend} />
          </OoChartGridItem>
        </OoChartGridContainer>

        <PlaytimeContainer>
          <Typography component="h2" variant="h6" align="center">{txt[3]}</Typography>
          <GamesByTimePlayedChart colors={COLORS} data={gamesByTimePlayedChart} />
        </PlaytimeContainer>
      </>
    );
  }
}

AccountCharts.propTypes = {
  accountCharts: PropTypes.object.isRequired,
};
export default AccountCharts;
import React from "react";
import * as PropTypes from "prop-types";
import { Typography, Grid } from "@material-ui/core";
import styled from "styled-components";
import Summary from "./Summary";
import ship from "./visual/ship-top-left-OberionIO.min.svg";
import wave from "./visual/backlog-challenge-wave-OberionIO.min.svg";
import { OoTypoLightGrey } from "../element/page-element/OoTypo";
import { grey3 } from "../element/theme";

const BackgroundDiv = styled.div`
  width: 100%;
  padding: 3rem 1.5rem;
  background-image: url(${ship}), url(${wave});
  background-size: auto, auto;
  background-position: top left, bottom;
  background-repeat: no-repeat, no-repeat;
`;
const BorderedGrid = styled(Grid)`
  border: solid ${grey3} 1px;
  margin: 2rem 1.5rem 0 1.5rem;
`;

const Overview = ({ overview }) => {
  // const schedule = `You are ${overview.schedule[0]} games behind schedule. The challenge ends in ${overview.schedule[1]} days!`;
  const schedule = `The challenge ends 31 December 2019`;

  return (
    <BackgroundDiv>
      {/*<Grid container spacing={3} */}
      <Grid container spacing={3} justify="center" alignItems="center">
        {/*<Grid item xs={12} sm={6}>*/}
          {/*sm={6} too small, causes cramped styles. needs a better solution!*/}
          <Summary backlogChallenge={overview.backlogChallenge} progressBarWidth="19rem" />
        {/*</Grid>*/}
        {/* <Grid item xs={12} sm={6}>
          <Grid container spacing={3}>

            {overview.stats.map( (el, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <OoTypoLightGrey variant="body1" component="p">{el[1]}</OoTypoLightGrey>
                <Typography variant="subtitle1" component="p">{el[0]}</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>*/}

        <BorderedGrid item xs={12}>
          <Typography variant="body1" align="center">{schedule}</Typography>
        </BorderedGrid>
      </Grid>


    </BackgroundDiv>
  );
};

Overview.propTypes = {
  overview: PropTypes.shape({
    backlogChallenge: PropTypes.object.isRequired,
    stats: PropTypes.array.isRequired,
    schedule: PropTypes.array.isRequired,
  }).isRequired,
};

export default Overview;
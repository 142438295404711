import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { DiscoveryTourExplore, TryRandom, DiscoveryTourTake } from "../library/HeroBanners";

const SpaceDiv = styled.div`
  margin-top: 1rem;
`;
const LinkDiv = styled.div`
  margin: 1.5rem 0;
`;

const BlogTeaserSideBar = ({}) => {
  return (
    <>
      <Typography variant="h6" paragraph>Latest blog post</Typography>
      <DiscoveryTourExplore illustrationHeight="15rem" />
      <SpaceDiv />
      <TryRandom illustrationHeight="5rem" />
      <SpaceDiv />
      <DiscoveryTourTake illustrationHeight="5rem" />

      <LinkDiv>
        <Typography variant="body1" color="secondary">More blog posts</Typography>
      </LinkDiv>
    </>
  );
};

BlogTeaserSideBar.propTypes = {};

export default BlogTeaserSideBar;
import React from "react";
import DefaultTooltipContent from "recharts/lib/component/DefaultTooltipContent";
import styled from "styled-components";

const TooltipContainer = styled.div`
    background-color: white;
    opacity: 0.9;
    padding: 0.5rem 0.75rem;
    color: black;
`;

export const GamesByTimePlayedChartTooltip = ({ active, payload, label }) => {
  if (active) {
    if (label === "Never played") {
      return (
        <TooltipContainer>
          <p>You never played {payload[0].value} games</p>
        </TooltipContainer>
      );
    }
    return (
      <TooltipContainer>
        <p>You played {payload[0].value} games for {label} hours</p>
      </TooltipContainer>
    );
  }
  return null;
};


export const HoursToPlayChartTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <TooltipContainer>
        <p>You played a total of {payload[0].value} hours</p>
        <p>With your current average time for each game, you have {payload[1].value} hours more to play</p>
      </TooltipContainer>
    );
  }
  return null;
};


/**
 * @see https://github.com/recharts/recharts/issues/275
 */
export const CustomTooltip1 = props => {
  // we don't need to check payload[0] as there's a better prop for this purpose
  if (!props.active) {
    // I think returning null works based on this: http://recharts.org/en-US/examples/CustomContentOfTooltip
    return null;
  }
  // mutating props directly is against react's conventions
  // so we create a new payload with the name and value fields set to what we want
  const newPayload = [
    {
      name: "Name",
      // all your data which created the tooltip is located in the .payload property
      value: props.payload[0].payload.name,
      // you can also add "unit" here if you need it
    },
    // ...props.payload,
  ];

  // we render the default, but with our overridden payload
  return <DefaultTooltipContent {...props} payload={newPayload} />;
};
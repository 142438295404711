import React, { useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import Layout from "../components/element/layout/Layout";
import SEO from "../components/element/SEO";
import theme from "../components/element/theme";
import PageBanner from "../components/element/page-element/PageBanner";
import { AuthContext } from "../components/authentication/AuthContextProvider";
import {
  ContentDiv,
  MainGrid,
  SideGrid,
  defaultPaper,
  defaultPaperNoBackground,
  illustrationPaper,
  listPaper,
} from "../components/element/layout/ContentContainers";
import { data } from "../components/backlog-challenge/backlogSampleData";
import Overview from "../components/backlog-challenge/Overview";
import OoPaper from "../components/element/page-element/OoPaper";
import AccountStats from "../components/library/AccountStats";
import BadgesColumn from "../components/library/badge/BadgesColumn";
import AccountCharts from "../components/backlog-challenge/chart/Charts";
import {
  CelesteCompilation,
  GamingLibrary,
  PreviewExplanation,
} from "../components/library/HeroBanners";
import BorderedExplainer from "../components/backlog-challenge/BorderedExplainer";
import HorizontalBar from "../components/backlog-challenge/chart/HorizontalBar";
import BlogTeaserSideBar from "../components/backlog-challenge/BlogTeaserSideBar";


const seo = {
  title: "Backlog Challenge - Oberion",
  description: null,
};
const text = [
  "2019 Backlog Challenge",
  "Participate in the backlog challenge to finally play the games you bought to play \"later\"!",
];

const BacklogChallenge = () => {
  const authContext = useContext(AuthContext);

  let pageBannerPreviewNotice;
  let overview;
  let accountStats;
  let accountCharts;
  let horizontalBar;

  /* --- user logged in --- */
  if (authContext.getUserObject() != null && authContext.getUserObject().accountStats != null) {
    pageBannerPreviewNotice = null;
    overview = (<Overview overview={data.overview} />);
    accountStats = (<AccountStats accountStats={authContext.getUserObject().accountStats} />);
    accountCharts = (<AccountCharts accountCharts={authContext.getUserObject().accountCharts} />);
    horizontalBar = (
      <HorizontalBar ownedGames={authContext.getUserObject().accountStats.ownedGames}
                     playedGames={authContext.getUserObject().accountStats.playedGames} />
    );
  }

  /* --- user not logged in > show preview data --- */
  else {
    overview = (<Overview overview={data.overview} />);
    accountStats = (<AccountStats accountStats={data.accountStats} />);
    accountCharts = (<AccountCharts accountCharts={data.accountCharts} />);
    horizontalBar = (
      <HorizontalBar ownedGames={data.accountStats.ownedGames}
                     playedGames={data.accountStats.playedGames} />
    );
    const previewNotice = (
      <Typography variant="h2" color="secondary" gutterBottom>
        This is PREVIEW. Log in to get your own Challenge!
      </Typography>
    );
    pageBannerPreviewNotice = (
      <PageBanner extraComponent={previewNotice} minHeight="10rem"
                  backgroundColor={theme.palette.background.paper} />
    );
  }

  return (
    <Layout hideNewsletter>
      <SEO {...seo} />
      <PageBanner heading={text[0]} subheading={text[1]} minHeight="15rem" />
      {pageBannerPreviewNotice}

      <ContentDiv>
        <Grid container spacing={3}>
          <MainGrid item sx={12} md={8}>

            <OoPaper {...defaultPaperNoBackground}>
              <BorderedExplainer />
            </OoPaper>

            <OoPaper {...illustrationPaper}>
              {overview}
            </OoPaper>

            <OoPaper {...defaultPaperNoBackground}>
              {accountStats}
            </OoPaper>

            <OoPaper {...defaultPaperNoBackground}>
              {horizontalBar}
            </OoPaper>

            <OoPaper {...defaultPaperNoBackground}>
              {accountCharts}
            </OoPaper>

          </MainGrid>
          <SideGrid item sx={12} md={4}>

            <OoPaper {...defaultPaper}>
              <BadgesColumn />
            </OoPaper>

            <OoPaper {...defaultPaperNoBackground}>
              <PreviewExplanation />
            </OoPaper>

            <OoPaper {...defaultPaperNoBackground}>
              <GamingLibrary illustrationHeight="15rem" />
            </OoPaper>

            <OoPaper {...listPaper}>
              <CelesteCompilation illustrationHeight="15rem" />
            </OoPaper>

            {/*<OoPaper {...listPaper}>
              <BlogTeaserSideBar illustrationHeight="15rem" />
            </OoPaper>*/}


          </SideGrid>
        </Grid>
      </ContentDiv>
    </Layout>
  );
};

export default BacklogChallenge;